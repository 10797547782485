import React, { useContext } from "react";
import { Link } from "react-router-dom";
import NavButton from "./../NavButton";
import CONSTS from "./../../consts";
import HomeMapping from "./../../mappings/home";

const { WEBMAPPINGTYPES } = CONSTS;

const Footer = () => {
  let locationMapping = HomeMapping.locations;

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length >= 10) {
      return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
        3,
        6
      )}-${phoneNumber.substring(6, 10)}`;
    }
    return phoneNumber;
  };

  return (
    <div>
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-section nav">
            <h3>EXPLORE</h3>
            <ul>
              <Link to="/locations">
                <li>Locations</li>
              </Link>
              <Link to="/services">
                <li>Services and Pricing</li>
              </Link>
              <Link to="/about">
                <li>About</li>
              </Link>
              <Link to="/contact">
                <li>Contact</li>
              </Link>
              <Link to="/contact">
                <li>Book Reservation</li>
              </Link>
            </ul>
          </div>
          <div className="footer-section locations">
            <h3>LOCATIONS</h3>
            <div className="location-container">
              <span className="location">
                <h3>Doghouse on</h3>
                <h1>BELMONT</h1>
                <p>4325 Belmont Ave</p>
                <p>Dallas, TX 75204</p>
                <p>doghousebelmont@gmail.com</p>
                <p>469-729-9080</p>
              </span>
              <span className="location">
                <h3>Doghouse on</h3>
                <h1>MAPLE</h1>
                <p>5513 Maple Ave</p>
                <p>Dallas, TX 75232</p>
                <p>doghouseonmaple@gmail.com</p>
                <p>214-812-9552</p>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-bottom-container">
          <p>© Abbie’s Doghouse, 2021. All rights reserved</p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
