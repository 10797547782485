import React, { useContext, useRef, useEffect, useState } from "react";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import HomeMapping from "./../../mappings/home";

import Slide from "./Slide";
import TextSlide from "./TextSlide";

const _Carousel = () => {
  const carouselRef = useRef(null);
  const intervalRef = useRef(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [carouselFinishedTransition, setCarouselFinishedTrasition] = useState(
    true
  );
  const [arrowClicked, setArrowClicked] = useState(true);
  
  let carouselMapping = HomeMapping.carousel;
  
  //dispose of interval properly
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (carouselIndex === carouselMapping.length - 1) {
        carouselRef.current.goTo(0);
        setCarouselIndex(0);
      } else {
        carouselRef.current.goTo(carouselIndex + 1);
        setCarouselIndex(carouselIndex + 1);
      }
    }, 6000);
    return () => clearInterval(intervalRef.current);
  }, [carouselIndex, carouselMapping.length]);

  const arrowOnClick = (isLeft) => {
    setArrowClicked(true);
    if (carouselFinishedTransition) {
      if (isLeft) {
        let index =
          carouselIndex === 0
            ? carouselMapping.length - 1
            : carouselIndex - 1;
        setCarouselIndex(index);
        carouselRef.current.prev();
      } else {
        let index =
          carouselIndex === carouselMapping.length - 1
            ? 0
            : carouselIndex + 1;
        setCarouselIndex(index);
        carouselRef.current.next();
      }
      clearInterval(intervalRef.current);
    }
  };

  return (
    <div className="carousel-container">
      <div className="carousel-text-area">
        {carouselMapping.map((carouselItem, i) => {
          return (
            <TextSlide
              key={i}
              isActive={carouselIndex === i}
              carouselItem={carouselItem}
              arrowOnClick={arrowOnClick}
            />
          );
        })}
      </div>

      <Carousel
        beforeChange={() => {
          setCarouselFinishedTrasition(false);
          setArrowClicked(false);
        }}
        afterChange={(current) => {
          setCarouselFinishedTrasition(true);
          if (!arrowClicked) {
            setCarouselIndex(current);
          }
        }}
        ref={(ref) => (carouselRef.current = ref)}
        className="carousel-main"
        effect="fade"
        autoplay={false}
        dots={false}
      >
        {carouselMapping.map((carouselItem, i) => {
          return <Slide key={i} carouselItem={carouselItem} />;
        })}
      </Carousel>
      
    </div>
  );
};

export default _Carousel;
