import React, { useContext } from "react";
import { ServicesStore } from "./../../store/ServicesStore";
import NavButton from "./../NavButton";
import CONSTS from "./../../consts/";
import HomeMapping from "./../../mappings/home";

const { WEBMAPPINGTYPES } = CONSTS;

const TaxiSection = () => {
  const servicesContext = useContext(ServicesStore);
  let { setService } = servicesContext;

  let taxiMappingItem = HomeMapping.services.Taxi;

  return (
    <div className="taxi-section-container">
      <div className="img-content">
        <img src="img/taxi3.webp" alt="Taxi Services - Abbie's Doghouse - Daycare and boarding in Dallas, TX"/>
      </div>
      <div className="text-content">
        <h1>{taxiMappingItem.Title}</h1>
        <p>{taxiMappingItem.Description}</p>
        <NavButton
          linkTo={WEBMAPPINGTYPES.ButtonLinkTo.Services}
          type={WEBMAPPINGTYPES.Button.Primary}
          props={{
            style: { margin: 0, marginTop: 20 },
            onClick: () => {
              setService("taxi");
            },
          }}
          text="TAXI SERVICE"
        />
      </div>
    </div>
  );
};
export default TaxiSection;
