import React from "react";


const FirstTimeContent = () => {
  return (
    <div>
      <h1>Your First Visit</h1>
      <br />
      <h2>Free 2 Hour Trial</h2>
      <p>
        We offer a free 2 hour trial for new dogs to come and check us out! The
        drop off time for weekday free 2 hour trials or first daycare days is
        always 7:30am. This way your pup can meet the other kids as they arrive
        at school instead of walking into the full pack! On your first day of
        school its never fun to be the last kid in the classroom. We also have
        Saturday trials at 10am, 10:30am, and 11am. Saturday trials do tend to
        book up faster than our weekday trials. To schedule just email us at
        your desired location!
      </p>
      <p>
        Please include your pups age, breed, and whether you are looking for
        daycare or boarding in your email. Let us know what day would be good
        for you and keep in mind it may take several weeks to get in for your
        first visit.
      </p>
      <p>
        IMPORTANT: For holiday boarding we require 5 full days of daycare prior
        to boarding for all new dogs!
      </p>
      <br />
      <h2>What You Will Need</h2>
      <p>
        For your first day you will need a client information sheet (fill out
        when your drop off or we will email you one in advance) and proof of
        current bordatella, distemper/parvo, fecal test, and rabies vaccines.
        You or your vet can email us proof of vaccines in advance or you can
        bring it with you.
      </p>
      <p>
        <i>
          Please make sure your dogs vaccine records are with you or that they
          are emailed PRIOR to your first visit! Your vet may not be available
          to provide these and for the safety of our dogs we cannot let dogs
          without current vaccines stay and play!
        </i>
      </p>
      <br />
      <h2>What Happens Upon Arrival</h2>
      <p>
        We will take your pup back to our acclimation yard and begin the process
        of making sure we have your paperwork filled out as soon as you arrive!
        Please keep in mind the 7:30am drop off time is GREAT for your pup but
        not so great for us humans. It is a popular drop off time and in
        addition to taking in daycare dogs we are still taking care of our
        overnight boarders! Your questions and concerns are important to us and
        we will be more than happy to answer them via email or over the phone as
        long as we aren't busy with the kids when you call! After we have your
        paperwork you are good to go.
      </p>
      <p>
        Your pup will choose when they are ready to leave the acclimation yard
        and go out to our main yard. It can be scary at first but as soon as
        they make some friends they will be loving the space to run and play!
      </p>
      <br />
      <h2>After The First Day</h2>
      <p>
        Especially if your pup hasn't been to daycare before they will likely be
        extremely tired. We do have a system that allows every dog plenty of
        breaks throughout the day but the first time in they don't understand
        the schedule or that they can nap and playtime will keep going
        afterwards! This level of exhaustion after daycare doesn't last long! As
        they get more fit and understand nap times it takes more and more
        playtime to tire them out. While it is uncommon larger dogs with lots of
        energy may have sore paws from running through our various yards and
        jumping off our picnic tables. We do have pad protector available to
        help their paws stay comfortable with the increased activity level.{" "}
      </p>
      <p>
        Please let us know if your pup is overly tired or has sore paws so we
        can give the extra breaks and apply pad protector! They are so excited
        to be with their friends they may not show these signs until they get
        home!
      </p>
      <br />
    </div>
  );
};

export default FirstTimeContent;
