export default {
  info1: {
    Title: "Belmont Ave",
    Text:
      "The Belmont Ave Doghouse is known for its huge outdoor play area as well as its large air conditioned indoor playrooms for our dogs to escape Dallas weather. Its yard is over 60 percent shaded with its large UV shades and permanent roofing along the back walls. Outside it also has picnic tables, baby pools, and misting fans for our dogs to enjoy. Dogs may be playing a round of fetch, lounging on a picnic table, or just enjoying the water but they all love spending time in the yard.",
  },
  info2: {
    Title: "Maple Ave",
    Text:
      "The Maple Ave Doghouse was built to compliment her Belmont sister location. This doghouse has a large indoor play area in addition to outdoor potty space compared to the Belmont's huge outdoor yard space. This location has enclosures surrounding the indoor play space that we hope to fill with fun agility equipment and activities for the pups.",
  },
  info3: {
    Title: "Keeping our space clean and tidy",
    Text:
      "We take sanitation seriously at Abbie's. After the first round of morning play we break up the dogs into smaller groups in order to sanitize everyday. After the yard is clean, the dogs are able to enjoy a freshly cleaned play area!",
  },
};