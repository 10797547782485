import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ServicesStore } from "./../../store/ServicesStore";

const ServiceCard = ({ title, text, imgSrc, center, serviceType }) => {
  const servicesContext = useContext(ServicesStore);
  let { setService } = servicesContext;

  return (
    <div className={`services-card-container ${center ? "center" : null}`}>
      <span className="img-container">
        <img src={`img/${imgSrc}`} alt="Services and Pricing - Abbie's Doghouse - Daycare and boarding in Dallas, TX"/>
      </span>
      <span className="text-container">
        <p className="header">{title}</p>
        <p className="description">{text}</p>
      </span>
    </div>
  );
};
export default ServiceCard;
