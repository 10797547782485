import React from "react";
import UserReview from "./userReview";
import NavButton from "./../NavButton";
import CONSTS from "./../../consts";

const { WEBMAPPINGTYPES } = CONSTS;

const Reviews = () => {
  return (
    <div className="review-container">
      <span className="header">
        <h1>What our human friends say about us:</h1>
        <img src="img/google-reviews.png" alt="Abbie's Doghouse - Daycare and boarding in Dallas, TX"/>
      </span>
      <div className="user-reviews">
        <div className="reviewers">
          <UserReview
            name="Shawna Watson"
            review="They treat my pup as if he is their own! Taxi service is clutch for those of us with nontraditional work hours. Abbie regularly emails customers to ensure we are up to date on policies/procedures/upcoming events."
            imgSrc="customer1.png"
            stars={5}
          />
          <UserReview
            name="Evan Schnittman"
            review="Nothing is more amazing than how excited Lucy gets pulling up to Abbie's... And I love how they knew her name immediately and seem to love her too!"
            imgSrc="customer2.png"
            stars={5}
          />
          <UserReview
            name="Shelby Haire"
            review="We love Abbie’s! All of the workers treat your dog like their own. They post pictures on their Facebook page throughout the day of all the pups playing, so you get to see what your pup is up to! Highly recommend for daycare for your pup in east Dallas!"
            imgSrc="customer3.png"
            stars={5}
          />
        </div>
      </div>
      <div className="button-container">
        <NavButton
          linkTo={"external"}
          text={"MORE BELMONT REVIEWS!"}
          type={WEBMAPPINGTYPES.Button.Primary}
          props={{onClick: () => { window.open('https://www.google.com/search?q=abbies+doghouse+on+belmont&oq=abbies+doghouse+on+belmont&aqs=chrome.0.69i59j46j0.3656j0j7&sourceid=chrome&ie=UTF-8#lrd=0x864e9f2bb1bd288d:0x940a50a80ef23cb6,1,,,') }}}
        />
        <NavButton
          linkTo={"external"}
          text={"MORE MAPLE REVIEWS!"}
          type={WEBMAPPINGTYPES.Button.Secondary}
          props={{onClick: () => { window.open('https://www.google.com/search?q=abbies+doghouse+on+maple&oq=abbies+doghouse+on+maple&aqs=chrome.0.69i59j46.2414j0j9&sourceid=chrome&ie=UTF-8#lrd=0x864e9d4f48d1785d:0xde379e59eac78134,1,,,') }}}
        />
      </div>
    </div>
  );
};
export default Reviews;
