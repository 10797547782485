import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import sizes from "react-sizes";

const mapStyles = {
  width: "100%",
  float: "left",
  height: "50vh",
};

export class MapContainer extends Component {
  state = {
    showingInfoWindowBelmont: false,
    showingInfoWindowMaple: false,
    belmontMarker: null,
    mapleMarker: null,
  };

  render() {
    let height = "50vh";
    switch (this.props.screenSize) {
      case "phone":
        height = "50vh";
        break;
      case "tablet":
        height = "50vh";
        break;
      default:
        height = "50vh";
        break;
    }

    return (
      <Map
        containerStyle={{ position: "relative" }}
        google={this.props.google}
        zoom={13}
        style={{ ...mapStyles, height: height }}
        initialCenter={{
          lat: 32.814708,
          lng: -96.810849,
        }}
      >
        <Marker
          id={"Belmont"}
          title={"Abbie's Doghouse on Belmont Ave"}
          position={{ lat: 32.808568, lng: -96.789069 }}
          onClick={(props, marker, e) => {
            this.setState({
              showingInfoWindowMaple: true,
              mapleMarker: marker
            });
          }}
        ></Marker>
        <InfoWindow marker={this.state.mapleMarker} visible={this.state.showingInfoWindowMaple}>
          <div>Abbie's Doghouse on Belmont</div>
        </InfoWindow>
        <Marker
          id={"Maple"}
          title={"Abbie's Doghouse on Maple Ave"}
          position={{ lat: 32.819853, lng: -96.835025 }}
          onClick={(props, marker, e) => {
            this.setState({
              showingInfoWindowBelmont: true,
              belmontMarker: marker
            });
          }}
        ></Marker>
        <InfoWindow marker={this.state.belmontMarker} visible={this.state.showingInfoWindowBelmont}>
          <div>Abbie's Doghouse on Maple</div>
        </InfoWindow>
      </Map>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  screenSize: width < 800 ? "phone" : width < 1180 ? "tablet" : "desktop",
});

export default sizes(mapSizesToProps)(
  GoogleApiWrapper({
    apiKey: "AIzaSyBnBRluvdZy_um2Cz79nTuFUpwWm4wFdw0",
  })(MapContainer)
);
