import React, { createContext, useState } from "react";

const ServicesStore = createContext();
const { Provider } = ServicesStore;

const ServicesProvider = ({ children }) => {
  const [service, setService] = useState("daycare");
  return (
    <Provider value={{ service, setService }} initialState={service}>
      {children}
    </Provider>
  );
};

export { ServicesStore, ServicesProvider };
