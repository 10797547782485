import React from "react";
const Menu = ({ menuItemsArr }) => {
  if (menuItemsArr)
    return (
      <div className="menu">
        {menuItemsArr.map((menuItem, i) => {
          return (
            <div className="menu-item" key={i}>
              <div className="main">
                <div className="text">{menuItem.item}</div>
                <div className="price">${menuItem.price}.00</div>
              </div>
              <div className="description">{menuItem.description}</div>
            </div>
          );
        })}
      </div>
    );
};
export default Menu;
