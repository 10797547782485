import React from "react";
import NavButton from "./../NavButton";
import CONSTS from './../../consts/index';

const TextSlide = ({ carouselItem, isActive, arrowOnClick }) => {
  let headerText = !!carouselItem.Header ? (
    <div className="header-text">{carouselItem.Header.Text}</div>
  ) : null;
  let subText = <div className="sub-text">Welcome to Abbie's Doghouse!</div>
  let button = !!carouselItem.Button ? (
    <NavButton linkTo={carouselItem.Button.LinkTo} type={CONSTS.WEBMAPPINGTYPES.Button.Primary}></NavButton>
  ) : null;
  return (
    <div
      className={"text-slide-container" + (isActive ? " active" : " inactive")}
    >      
      <a className={"arrow left"} onClick={() => {arrowOnClick(true)}}>
        <img src={"./img/arrow-left.svg"}/>
      </a>
      <a className={"arrow right"}  onClick={() => {arrowOnClick(false)}}>
        <img src={"./img/arrow-right.svg"}/>
      </a>
      {subText}
      {headerText}
      {button}
    </div>
  );
};

export default TextSlide;
