import React, { createContext, useState } from "react";

const ContactStore = createContext();
const { Provider } = ContactStore;

const ContactProvider = ({ children }) => {
  const [contact, setContact] = useState("belmont");
  return (
    <Provider value={{ contact, setContact }} initialState={contact}>
      {children}
    </Provider>
  );
};

export { ContactStore, ContactProvider };
