export default {
  daycare: {
    header: "Our daycare guarantees a fun and tiring day for your pup",
    text:
      "We take pride in offering excellent customized service for our daycare customers and strive to build relationships with each of our clients.​With indoor and outdoor play areas dogs play all day with their playgroup and have combined play time with other playgroups! Our dogs form close friendships and they will love coming to 'school' every morning.",
    package: {
      description:
        "Our customers' most favored choice is our package options. All of our daycare packages expire one full year after the purchase date and the days can be used at anytime during that given year.",
      prices: [
        {
          id: "pck1",
          item: "30 days",
          days: 30,
          price: 510,
          description: "($17 / day)",
        },
        {
          id: "pck2",
          item: "20 days",
          days: 20,
          price: 420,
          description: "($21 / day)",
        },
        {
          id: "pck3",
          item: "10 days",
          days: 10,
          price: 250,
          description: "($25 / day)",
        },
        {
          id: "pck4",
          item: "5 days",
          days: 5,
          price: 140,
          description: "($28 / day)",
        },
      ],
    },
    weekly: {
      description:
        "We also have weekly rates for clients that do not want to buy a larger package. To get on the weekly rate plan we keep a credit card on file and charge every Friday based on the number of days of daycare your dog attended that week. We call this the Charge on Friday Plan.",
      prices: [
        {
          id: "wk1",
          item: "1 day / week",
          days: 1,
          price: 30,
          description: "",
        },
        {
          id: "wk2",
          item: "2 days / week",
          days: 2,
          price: 54,
          description: "($27 / day)",
        },
        {
          id: "wk3",
          item: "3 days / week",
          days: 3,
          price: 72,
          description: "($24 / day)",
        },
        {
          id: "wk4",
          item: "4 days / week",
          days: 4,
          price: 80,
          description: "($20 / day)",
        },
        {
          id: "wk6",
          item: "5 days / week",
          days: 5,
          price: 90,
          description: "($18 / day)",
        },
        {
          id: "wk7",
          item: "6 days / week",
          days: 5,
          price: 108,
          description: "($18 / day)",
        },
      ],
    },
  },
  boarding: {
    header: "Staying away from home has never been so much fun!",
    text:
      "Boarding at the Dog House includes all day daycare - even on drop off and pick up day! Your dog will love playing all day long in our amazing indoor playrooms and outdoor yard and sleeping in our large rooms (never in a cage)! Every night we pull out our bedding and blankets so your dog can sleep comfortably! Overnight dogs are roomed according to temperament and size. Boarding with a buddy decreases anxiety so we do try to give all dogs a roommate! However, some dogs prefer to sleep alone and we can accommodate that as well. Because our boarding dogs play all day with our daycare dogs we require all new dogs to come in for a free 2 hour trial or full day of daycare prior to boarding if they have never been boarded or attended a dog daycare before.",
    prices: [
      {
        id: "brd1",
        item: "Price Per Night",
        days: 1,
        price: 42,
        description:
          "Owner brings food prepped and pre-bagged in individual meal bags",
      },
      {
        id: "brd2",
        item: "Price Per Night",
        days: 1,
        price: 45,
        description:
          "Owner does not bring food or does not pre-bag food into meal bags",
      },
    ],
    tips: {
      DropOffAndPickUp:
        "For our boarding dogs to be comfortable and tired we prefer all boarders be dropped off by 3 pm on their scheduled drop off day. If you cannot make it by 3 pm please let us know in advance. Unscheduled drop offs after 3 may result in a $20 late drop off fee. For drop offs after 5pm there may be a $50 late drop off fee. There is no charge for dropping off early on drop off day! We want your dog to be tired at bed time! Daycare is included in the price of boarding including all day daycare on drop off and pick up days (so drop off early and pick up as late as you'd like). If you need to extend your boarding please let us know by your last scheduled boarding day so we can get your dogs room all set up before closing! It takes time for us to get our cage free rooms ready for boarders and to decide on sleeping arrangements so if we don't hear from you by closing time we charge an additional $10/ night for all unscheduled nights.",
      GeneralInformation:
        "We highly recommend bringing your own food for boarding to avoid an upset stomach but we have plenty of food available here as well! To ensure we feed the correct amounts we also appreciate all food to come in 'pre-portioned' zip lock bags so we feed the exact amount you want us to feed for each meal. We have plenty of measuring cups and food bowls so no need to bring your own. We have many beds of all shapes and sizes available but beds from home are always welcome. We don't recommend bringing anything that is valuable and we don't recommend bringing toys or bones - we cannot guarantee their safe return! If you do bring beds, blankets, leashes, toys, bowls, measuring cups, or bones please label each item! Our dogs like to share so if your items aren't labelled it is more likely that they won't be returned. Due to limited storage space - items left at Dog House more than 5 days after boarding will become free game for the other dogs!",
      FoodGuide:
        "Pack each meal separately in a zip lock bag, put all bags into a larger bag or container for storage and transportation, pack extra food (your dog may burn more calories or your trip home may take longer than expected), labelling is great! If your dog is on medication or has wet food please do not put in the pre-packaged bags. We want to ensure dogs on medication swallow their pills and the dogs don't like the consistency of food that has been mixed with wet food if it isn't fresh.",
    },
  },
  grooming: {
    header: "",
    text: "",
    bath: {
      shortHair: {
        prices: [
          {
            id: "srv1",
            item: "Under 60 pounds",
            price: 25,
          },
          {
            id: "srv2",
            item: "Over 60 pounds",
            price: 35,
          },
        ],
      },
      longHair: {
        prices: [
          {
            id: "srv1",
            item: "Under 30 pounds",
            price: 25,
          },
          {
            id: "srv2",
            item: "30 - 45 pounds",
            price: 30,
          },
          {
            id: "srv3",
            item: "46 - 60 pounds",
            price: 35,
          },
          {
            id: "srv4",
            item: "61 - 75 pounds",
            price: 40,
          },
          {
            id: "srv5",
            item: "75 pounds and up",
            price: 45,
          },
        ],
      },
    },
    prices: [
      {
        id: "srv1",
        item: "Nail Trim w/ bath",
        price: 5,
      },
      {
        id: "srv2",
        item: "Nail Trim w/o bath",
        price: 10,
      },
      {
        id: "srv3",
        item: "Furminate / De-shedding (15 minutes)",
        price: 10,
      },
      {
        id: "srv4",
        item: "Brush out (15 minutes doodle / curly hair)",
        description: "can add on time at $1/ minute after first 15 minutes",
        price: 10,
      },
      {
        id: "srv5",
        item: "Anal Glands",
        description: "should be done with bath to avoid possible odor",
        price: 10,
      },
      {
        id: "srv6",
        item: "Sanitary Trim",
        price: 15,
      },
      {
        id: "srv7",
        item: "Teeth Brushing",
        price: 10,
      },
      {
        id: "srv8",
        item: "Spaw Treatment",
        description:
          "anal glands + nail trim + teeth + brushout + sanitary trim",
        price: 25,
      },
    ],
  },
  taxi: {
    prices: [
      {
        id: "tx1",
        item: "0 - 0.5 mile",
        price: 6,
      },
      {
        id: "tx2",
        item: "0.5 - 1 mile",
        price: 8,
      },
      {
        id: "tx2",
        item: "1 - 1.5 miles",
        price: 10,
      },
      {
        id: "tx3",
        item: "1.5 - 2 miles",
        price: 15,
      },
      {
        id: "tx4",
        item: "2 - 2.5 miles",
        price: 20,
      },
      {
        id: "tx5",
        item: "2.5 - 3.5 miles",
        price: 25,
      },
    ],
    additional: {
      prices: [
        {
          id: "tx1",
          item: "Concierge key pickup",
          price: 5,
        },
        {
          id: "tx2",
          item: "Far distance from parking",
          description:
            "If the home is far from parking or far away from lobby of the apartment",
          price: 5,
        },
      ],
    },
  },
};