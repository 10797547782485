import React, { useContext } from "react";
import { Modal, Descriptions, Button } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { ContactStore } from "./../../store/ContactStore";

const LocationCard = ({
  locationGeneralInformation,
  primary,
  contactType,
  locationHours,
}) => {
  const ContactContext = useContext(ContactStore);
  let { setContact } = ContactContext;

  let primaryOrSecondaryClassName = primary ? "primary" : "secondary";
  var now = new Date();
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let day = days[now.getDay()];

  const openHoursOfOperation = () => {
    Modal.info({
      title: `${locationGeneralInformation.DisplayName} - Hours of Operation`,
      content: (
        <Descriptions className="hoursOfOperationModal">
          {Object.keys(locationHours).map((v, i) => {
            let value = " Closed ";

            if (!!locationHours[v]) {
              value = `${moment(locationHours[v][0]).format(
                "H:mm A"
              )} - ${moment(locationHours[v][1]).format("h:mm A")}`;
              if (day === v) {
                value = <b>{value}</b>;
              }
            }

            return (
              <Descriptions.Item
                key={v}
                label={v}
                span={3}
                style={{ paddingBottom: 5 }}
              >
                {value}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      ),
    });
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length >= 10) {
      return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
        3,
        6
      )}-${phoneNumber.substring(6, 10)}`;
    }
    return phoneNumber;
  };

  const facebookOnClick = () => {
    if (contactType === "belmont") {
      window.open("https://www.facebook.com/doghousebelmont/");
    } else {
      window.open("https://www.facebook.com/DogHouseMaple/");
    }
  };

  const googleMapsOnClick = () => {
    if (contactType === "belmont") {
      window.open(
        "https://www.google.com/maps/place/Abbie's+Dog+House+on+Belmont/@32.8086078,-96.7891292,15z/data=!4m2!3m1!1s0x0:0x940a50a80ef23cb6?sa=X&ved=2ahUKEwjev_bumo3sAhVPA6wKHaL9D2UQ_BIwCnoECBcQCA"
      );
    } else {
      window.open(
        "https://www.google.com/maps/place/Abbie's+Dog+House+on+Maple/@32.8197735,-96.8351253,15z/data=!4m2!3m1!1s0x0:0xde379e59eac78134?sa=X&ved=2ahUKEwit-6nhmo3sAhUEI6wKHUmjA-gQ_BIwE3oECBYQCA"
      );
    }
  };

  return (
    <div className={`location-card-container`}>
      <div className="location-information">
        <span className="location-header">
          <p>Doghouse on</p>
          <h1>{locationGeneralInformation.DisplayName}</h1>
        </span>
        <span className="location-details">
          <p className={`address`}>{locationGeneralInformation.AddressLine1}</p>
          <p className={`address`}>{locationGeneralInformation.AddressLine2}</p>
          <p className={`address`}>{locationGeneralInformation.Email}</p>
          <p className={`address`}>{locationGeneralInformation.PhoneNumber}</p>
        </span>
        <div className="location-buttons">
          <Button className={"secondary"} onClick={openHoursOfOperation}>
            HOURS
          </Button>
          <Link
            to="/contact"
            onClick={() => {
              setContact(contactType);
            }}
          >
            <Button className={"secondary"}>CONTACT</Button>
          </Link>
          <Button className={"tertiary"} onClick={facebookOnClick}>
            <i className="fab fa-facebook-square"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default LocationCard;
