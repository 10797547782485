import React from "react";
import NavButton from "./NavButton";
import CONSTS from "../consts";

const { WEBMAPPINGTYPES } = CONSTS;

const CardDetails = ({ title, text, type, buttonType }) => {
  let imageSrc = "";
  switch (type) {
    case "daycare":
      imageSrc = "groupandlot.webp";
      break;
    case "grooming":
      imageSrc = "grooming2.webp";
      break;
    default:
      break;
  }

  if (buttonType === "secondary") {
    buttonType = WEBMAPPINGTYPES.Button.Secondary;
  }
  else
  {
    buttonType = WEBMAPPINGTYPES.Button.Primary;
  }

  return (
    <div className={`card-container`}>
      <span className={`img-container ${type}`}>
      </span>
      <span className="text-container">
        <p className="header">{title}</p>
        <p className="description">{text}</p>
        <NavButton
          linkTo={WEBMAPPINGTYPES.ButtonLinkTo.Reserve}
          type={buttonType}
          props={{ style: { margin: 0 } }}
        />
      </span>
    </div>
  );
};
export default CardDetails;
