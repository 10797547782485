import React, { useState, useRef } from "react";
import { Helmet } from 'react-helmet'
import Location from "./../components/Location";
import InfoSection from "./../components/InfoSection";
import ImgInfoSection from "./../components/ImgInfoSection";
import FacebookSection from "./../components/facebookSection";
import CardDetails from "./../components/cardDetails";
import { Tabs, Carousel, Button } from "antd";
import LocationMapping from "./../mappings/locations";
import MapContainer from "./../components/MapContainer";

const { TabPane } = Tabs;

const LocationScreen = () => {
  const carouselRef = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState("belmont");
  const [carouselFinishedTransition, setCarouselFinishedTrasition] = useState(
    true
  );
  const [navClicked, setNavClicked] = useState(true);

  let locationContent = LocationMapping;

  const onClick = (location) => {
    setNavClicked(true);
    if (carouselFinishedTransition) {
      if (location === "belmont") {
        setSelectedLocation("belmont");
        carouselRef.current.goTo(0);
      } else {
        setSelectedLocation("maple");
        carouselRef.current.goTo(1);
      }
    }
  };

  return (
    <div id="screen-container">
      <Helmet>
        <title>Locations | Abbie's Doghouse</title>
      </Helmet>
      <Location hideSubtitle={false} />
      <div className="location-map">
        <MapContainer />
      </div>

      <InfoSection
        title={
          "Abbie's doghouses are completely cage-free daycare and boarding facilities in Dallas"
        }
        subtitle={"DOGHOUSE DETAILS"}
        text={
          "We currently have two amazing Doghouses in Dallas, TX. They each have their own unique features and characteristics! Find out more about each location below."
        }
        props={{ style: { paddingBottom: 0 } }}
      />
      <div
        className="location-details-container"
        style={{ float: "left", width: "100%" }}
      >
        <div className="container button-container">
          <Button
            onClick={() => {
              onClick("belmont");
            }}
            className={
              "secondary " + (selectedLocation === "belmont" ? "active" : "")
            }
          >
            DOGHOUSE ON BELMONT
          </Button>
          <Button
            onClick={() => {
              onClick("maple");
            }}
            className={
              "secondary " + (selectedLocation === "maple" ? "active" : "")
            }
          >
            DOGHOUSE ON MAPLE
          </Button>
        </div>
        <Carousel
          beforeChange={() => {
            setCarouselFinishedTrasition(false);
            setNavClicked(false);
          }}
          afterChange={(current) => {
            setCarouselFinishedTrasition(true);
            if (!navClicked) {
              setSelectedLocation(current);
            }
          }}
          autoplay={false}
          dots={false}
          ref={(ref) => (carouselRef.current = ref)}
        >
          <CardDetails
            key={"belmont"}
            title={locationContent.info1.Title}
            text={locationContent.info1.Text}
            type={"belmont"}
          />
          <CardDetails
            key={"maple"}
            title={locationContent.info2.Title}
            text={locationContent.info2.Text}
            type={"maple"}
          />
        </Carousel>
      </div>
      <ImgInfoSection
        subtitle={"SANTITATION"}
        title={locationContent.info3.Title}
        text={locationContent.info3.Text}
        imgSrc={"lot.webp"}
      />
      <FacebookSection />
    </div>
  );
};
export default LocationScreen;
