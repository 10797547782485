import React, { useState } from "react";
import { Form, Input, Button, Result, message } from "antd";
import {
  MailOutlined,
  UserOutlined,
  AlignLeftOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import * as API from "./../../api";

const ContactForm = ({ locationEmail, locationGeneralInformation }) => {
  const [form] = Form.useForm();
  let [emailSentConfirmation, setEmailSentConfirmation] = useState(false);
  let [emailSendPending, setEmailSendPending] = useState(false);

  const submitForm = () => {
    form.submit();
  };

  const onFormSubmitFinish = async (values) => {
    setEmailSendPending(true);
    if (
      await API.SendEmail(
        locationEmail,
        values.name,
        values.email,
        values.subject,
        values.message
      )
    ) {
      setEmailSentConfirmation(true);
      setEmailSendPending(false);
    } else {
      setEmailSentConfirmation(false);
      setEmailSendPending(false);
      message.error(
        "An error has occured while trying to send an email. Please try again later."
      );
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    // if (phoneNumber.length >= 10) {
    //   return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
    //     3,
    //     6
    //   )}-${phoneNumber.substring(6, 10)}`;
    // }
    return phoneNumber;
  };

  const buttonProp = emailSendPending ? { loading: true } : {};
  const formLocationName =
    locationGeneralInformation.DisplayName === "BELMONT"
      ? "Doghouse on Belmont"
      : "Doghouse on Maple";

  return (
    <div className="contact-form-container">
      <div className="address-information-container">
        <h1>
          <i className="fas fa-map-marker-alt"></i>
          {locationGeneralInformation.DisplayName}
        </h1>
        <span className="location-text-section">
          <p>{locationGeneralInformation.AddressLine1}</p>
          <p>{locationGeneralInformation.AddressLine2}</p>
        </span>
        <span className="location-text-section">
          <p>
            <i className="fas fa-phone-alt"></i>
            {formatPhoneNumber(locationGeneralInformation.PhoneNumber)}
          </p>
        </span>
        <span className="location-text-section">
          <p>
            <i className="fas fa-envelope"></i>
            {locationGeneralInformation.Email}
          </p>
        </span>
      </div>
      <div className="form-container">
        <div className="form">
          <Form
            name={`${locationGeneralInformation.DisplayName}-Contact-Form`}
            form={form}
            onFinish={onFormSubmitFinish}
          >
            <span>
              <Form.Item name="sending to">
                <Input
                  placeholder={"To: " + formLocationName}
                  prefix={<HomeOutlined />}
                  bordered={false}
                  value={"To: " + formLocationName}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: "Please tell us your name!" },
                ]}
              >
                <Input
                  placeholder="Name"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  bordered={false}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message:
                      "Please input a valid email so that we can get back to!",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  prefix={<MailOutlined />}
                  bordered={false}
                />
              </Form.Item>
            </span>
            <Form.Item
              name="subject"
              rules={[
                {
                  required: true,
                  message: "Please tell us what you are inquiring about!",
                },
              ]}
            >
              <Input
                placeholder="Subject"
                prefix={<AlignLeftOutlined />}
                bordered={false}
              />
            </Form.Item>
            <Form.Item
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please input your message here!",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Message"
                bordered={false}
                autoSize={false}
                style={{ height: 270 }}
              />
            </Form.Item>
          </Form>
          <Button
            type="secondary"
            htmlType="submit"
            onClick={submitForm}
            {...buttonProp}
            className="form-button"
          >
            <MailOutlined />
            SEND MESSAGE
          </Button>
        </div>
        {emailSentConfirmation ? (
          <Result
            status="success"
            title="Email Sent!"
            subTitle="Thank you for reaching out. We'll get back to you as soon as possible!"
            extra={[
              <Button
                type="primary"
                onClick={() => {
                  setEmailSentConfirmation(false);
                  form.resetFields();
                }}
              >
                <MailOutlined /> Send another email
              </Button>,
            ]}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ContactForm;
