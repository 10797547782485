import CONSTS from "./../consts/index";
import moment from "moment";
let { WEBMAPPINGTYPES } = CONSTS;

class SlideContent {
  constructor(id, header, subtext, button, imageSrc) {
    this.id = id;
    this.Header = header;
    this.Subtext = subtext;
    this.Button = button;
    this.ImageSrc = imageSrc;
  }
}

class SlideContentText {
  constructor(text, fontSize, alignment) {
    this.Text = text;
    this.FontSize = fontSize;
    this.Alignment = alignment;
  }
}

class SlideContentButton {
  constructor (linkTo, text, type) {
    this.LinkTo = linkTo;
    this.Text = text;
    this.Type = type;
  }
}

let carousel1_button1 = new SlideContentButton(
  "services",
  "Our Services",
  WEBMAPPINGTYPES.Button.Primary
);
let carousel1_header = new SlideContentText(
  "Your best pups deserve the best care.",
  75,
  WEBMAPPINGTYPES.Alignment.Left
);
let carousel1_subtext = new SlideContentText(
  "Come see where best buds are made and why our dogs love coming to  their second homes.",
  75,
  WEBMAPPINGTYPES.Alignment.Left
);
// let carousel1_subtext = new SlideContentText("Providing a second home for your furry friends.", 25, WEBMAPPINGTYPES.Alignment.Left);
let carousel1_content = new SlideContent(
  "carousel1",
  carousel1_header,
  carousel1_subtext,
  carousel1_button1,
  "carousel1.webp"
);
//--
//--
let carousel2_button1 = new SlideContentButton(
  "location",
  "Our Services",
  WEBMAPPINGTYPES.Button.Primary
);
let carousel2_header = new SlideContentText(
  "Our new Maple Doghouse is officially open!",
  75,
  WEBMAPPINGTYPES.Alignment.Left
);
let carousel2_subtext = new SlideContentText(
  "Walk-ins are welcome, or you can also call or email us for an appointment! View our locations for more details.",
  75,
  WEBMAPPINGTYPES.Alignment.Left
);
// let carousel1_subtext = new SlideContentText("Providing a second home for your furry friends.", 25, WEBMAPPINGTYPES.Alignment.Left);
let carousel2_content = new SlideContent(
  "carousel2",
  carousel2_header,
  carousel2_subtext,
  carousel2_button1,
  "maple_carousel.webp"
);

let carousel = [carousel1_content, carousel2_content];

class ButtonModel {
  constructor(linkTo, type) {
    this.LinkTo = linkTo;
    this.Type = type;
  }
}
let homeInfoSectionButton1 = new ButtonModel(
  WEBMAPPINGTYPES.ButtonLinkTo.Reserve,
  WEBMAPPINGTYPES.Button.Primary
);
let homeInfoSectionButton2 = new ButtonModel(
  WEBMAPPINGTYPES.ButtonLinkTo.About,
  WEBMAPPINGTYPES.Button.Secondary
);

let infoSection = {
  Title:
    "We are an owner run business that will always put our dogs first.",
  Text:
    "Abbie’s Dog House on Belmont is a completely cage-free dog daycare and boarding facility in Dallas, TX. Our goal is to make each of our clients feel like a part of our family. Everyone at Abbie's will treat your dog like they are our own!",
  ButtonArr: [homeInfoSectionButton1, homeInfoSectionButton2],
};

let services = {
  Daycare: {
    Title: "Daycare",
    Description:
      "With large outdoor and indoor spaces, we provide the perfect environment for your dogs to socialize and have fun!",
  },
  Boarding: {
    Title: "Boarding",
    Description:
      "Staying away from home has never been more fun! Your dogs will be very comfortable sleeping in our large rooms (never in a cage)!",
  },
  Grooming: {
    Title: "Grooming",
    Description:
      "Treat your dog to a spaw day! We offer various grooming services during your dog’s stay at Abbie’s.",
  },
  Taxi: {
    Title: "Need a lift? Check out our taxi service!",
    Description: `For owners out there who need an extra paw, we provide a taxi service for eligible residencies where we pick up and drop off your dog!
    To check eligibility and to apply, click the button below.`,
  },
};

class LocationItem {
  constructor(
    id,
    displayName,
    addressLine1,
    addressLine2,
    phoneNumber,
    facebookUrl,
    hours,
    email
  ) {
    this.id = id;
    this.GeneralInformation = {
      DisplayName: displayName,
      AddressLine1: addressLine1,
      AddressLine2: addressLine2,
      PhoneNumber: phoneNumber,
      FacebookUrl: facebookUrl,
      Email: email,
    };
    this.Hours = hours;
  }
  Cast(obj) {
    for (var prop in obj) {
      this[prop] = obj[prop];
    }
  }
}

class HoursItem 
{
  constructor(mon,tue,wed,thu,fri,sat,sun) {
    this.Monday = mon;
    this.Tuesday = tue;
    this.Wednesday = wed;
    this.Thursday = thu;
    this.Friday = fri;
    this.Saturday = sat;
    this.Sunday = sun;
  }
}
let time = [
  moment().set({ hour: 7, minute: 30 }),
  moment().set({ hour: 18, minute: 0 }),
];

let belmontHours = new HoursItem(
  time,
  time,
  time,
  time,
  time,
  [
    moment().set({ hour: 9, minute: 0 }),
    moment().set({ hour: 15, minute: 0 }),
  ],
  null
);
let mapleHours = new HoursItem(time, time, time, time, time, null, null);

let belmontLocation = new LocationItem(
  "Belmont",
  "BELMONT",
  "4325 Belmont Ave",
  "Dallas, TX 75204",
  "469-729-9080",
  "https://www.facebook.com/doghousebelmont/",
  belmontHours,
  "doghousebelmont@gmail.com"
);
let mapleLocation = new LocationItem(
  "Maple",
  "MAPLE",
  "5513 Maple Ave",
  "Dallas, TX 75235",
  "214-812-9552",
  "https://www.facebook.com/DogHouseMaple/",
  mapleHours,
  "doghouseonmaple@gmail.com"
);

let locations = [belmontLocation, mapleLocation];

export default {
  carousel,
  infoSection,
  services,
  locations
};