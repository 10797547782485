import React from "react";
const Subheader = ({ text, props, secondary }) => {
  let imgSrc = "./img/paw-pointer.svg";
  if (secondary) {
    imgSrc = "./img/white-paw-pointer.svg";
  }

  return (
    <div className={secondary ? "subheader secondary" : "subheader"} {...props}>
      <h3>
        <img
          src={imgSrc}
          alt="Abbie's Doghouse - Daycare and boarding in Dallas, TX"
        />
        {text}
      </h3>
    </div>
  );
};
export default Subheader;
