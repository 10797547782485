import React from "react";
import ReactDOM from "react-dom";
// import TagManager from 'react-gtm-module';

import "@fortawesome/fontawesome-free/css/all.min.css";
import App from "./App";

// const tagManagerArgs = {
//   gtmId: process.env.GTM_ID
// }
// TagManager.initialize(tagManagerArgs);

// import './index.css';
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
