import React, { useState, useRef } from "react";
import { Helmet } from 'react-helmet'
import InfoSection from "./../components/InfoSection";
import ImgInfoSection from "./../components/ImgInfoSection";
import CompactInfoSection from "./../components/compactInfoSection";
import FacebookSection from "./../components/facebookSection";
import FirstTime from "./../components/FirstTime";
import { Tabs, Button, Carousel } from "antd";

const { TabPane } = Tabs;

const AboutScreen = () => {
  const carouselRef = useRef(null);
  const [carouselIndex, setCarouselIndex] = useState(1);
  const [carouselFinishedTransition, setCarouselFinishedTrasition] = useState(
    true
  );
  const [navClicked, setNavClicked] = useState(true);

  const onClick = (index) => {
    setNavClicked(true);
    if (carouselFinishedTransition) {
      switch (index) {
        case 1:
          setCarouselIndex(1);
          carouselRef.current.goTo(0);
          break;
        case 2:
          setCarouselIndex(2);
          carouselRef.current.goTo(1);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div id="screen-container" className="about-container">
      <Helmet>
        <title>About | Abbie's Doghouse</title>
      </Helmet>
      <InfoSection
        title={
          "We are an owner run business that will always put our dogs first."
        }
        subtitle={"WHO WE ARE"}
        text={
          "We are a completely cage-free dog daycare and boarding facility. We take in fewer dogs than other facilities in Dallas, because we want each of our staff members knows each and every one of our dogs. We know what they like, quirks and odd behaviors, and what they dislike. Our goal is to have our dogs drag their owners through the doors! We want to be their favorite place, a place they feel safe and a place where they are known. We are here for the dogs!"
        }
      />
      <ImgInfoSection
        title={"Our Caring Staff"}
        subtitle={"FACULTY"}
        text={
          "We always have experienced dog handlers present to supervise our kids at play. You may spy our handlers howling with the pack during 'Choir Practice', refilling our bowls and pools with fresh, clean water, scooping our yard clean, and having too much fun playing with the dogs. Our handlers are NOT temporary employees. Dogs are our passion and we only hire the most experienced handlers out there! Our job is to be your dogs best friend. We take our job very seriously! Want to see us in the yard? There is a window from the lobby so you can spy on us at pick up and drop off time!"
        }
        imgSrc={"staff3.webp"}
      />

      <InfoSection
        subtitle={"SAFETY REQUIREMENTS"}
        title={"Safety of your dog is our top priority at Abbie's Doghouse"}
        props={{ style: { paddingBottom: 0 } }}
      />
      <div style={{ width: "100%", backgroundColor: "#F0F9F9" }}>
        <div className="container button-container">
          <Button
            onClick={() => {
              onClick(1);
            }}
            className={"secondary " + (carouselIndex === 1 ? "active" : "")}
          >
            SAFTEY REQUIREMENTS
          </Button>
          <Button
            onClick={() => {
              onClick(2);
            }}
            className={"secondary " + (carouselIndex === 2 ? "active" : "")}
          >
            OTHER CONCERNS
          </Button>
        </div>
      </div>
      <div style={{ float: "left", width: "100%", backgroundColor: "#F0F9F9" }}>
        <Carousel
          style={{ paddingBottom: 70 }}
          beforeChange={() => {
            setCarouselFinishedTrasition(false);
            setNavClicked(false);
          }}
          afterChange={(current) => {
            setCarouselFinishedTrasition(true);
            if (!navClicked) {
              setCarouselIndex(current);
            }
          }}
          autoplay={false}
          dots={false}
          ref={(ref) => (carouselRef.current = ref)}
        >
          <div key={1} className="container" style={{ display: "block" }}>
            <CompactInfoSection
              props={{ style: { marginBottom: 0, marginTop: 20 } }}
              header={"Vaccines"}
              text={
                "We require all dogs be fully vaccinated, dogs over 8 months old must be spayed or neutered, dogs must be on a monthly flea preventative, and of course all dogs must get along with other dogs! "
              }
            />
            <CompactInfoSection
              props={{ style: { marginTop: 0 } }}
              text={
                "The Vaccines we require are Bordatella, Distemper/ Parvo, and Rabies. We also require the Influenza (H3N2) vaccine or a signed flu vaccine waiver (provided when you sign up for daycare). Please have your vet email us these vaccines prior to your first visit! You may also email or bring in paperwork with you! For the safety of our kids if we do not have your dogs vaccine records your dog cannot stay and play! We do offer the bordatella (kennel cough) vaccine for $15 if your dog is not current. To receive a bordatella at our facility and stay and play they must have had the bordatella vaccine previously! "
              }
            />
            <CompactInfoSection
              props={{ style: { marginBottom: 0, marginTop: 20 } }}
              header={"Communicable Diseases"}
              text={
                "For the safety of our dogs we cannot take in any dogs with communicable diseases. Please inform us if your dog has been diagnosed with any communicable diseases in the last 90 days prior to coming in for daycare or boarding! If your dog has had a communicable disease we may require a note from your vet saying that your dog is healthy and ready for play!"
              }
            />
            <CompactInfoSection
              props={{ style: { marginBottom: 0, marginTop: 20 } }}
              header={"Flea Prevention"}
              text={
                "Your dog MUST be on a monthly flea preventative to come to daycare! We check all of our dogs for fleas regularly! If your dog has fleas they will be given a Capstar and a $12 charge will be added to your bill! We will let you know your dog has fleas and can also administer frontline for $20-$22 and do a flea bath for $25 if you would like to add these services. "
              }
            />
          </div>
          <div key={2} className="container" style={{ display: "block" }}>
            <CompactInfoSection
              props={{ style: { marginBottom: 0, marginTop: 20 } }}
              header={"Exhaustion"}
              text={
                "Especially after their first few days dogs will be exhausted after daycare! This is normal and as they adjust to the schedule it will be harder and harder to wear them out!"
              }
            />
            <CompactInfoSection
              props={{ style: { marginBottom: 0, marginTop: 20 } }}
              header={"Water / Food"}
              text={
                "Dogs may seem starving or very thirsty right when they get home! We have fresh water available at all times and most dogs drink a lot while here! However, when they first get home and relax they will most likely be hungry and thirsty. If your dog is excessively hungry or losing weight please send them in with a lunch! We would be happy to feed them an extra meal! "
              }
            />
            <CompactInfoSection
              props={{ style: { marginBottom: 0, marginTop: 20 } }}
              header={"Respiratory Infection/ Kennel Cough"}
              text={
                "Just like kids that go to school dogs that come to school are in a closer proximity to transmittable diseases. Dogs that are coughing are sent home immediately but the transmission is quick! This is why we require the bordatella vaccine and are very strict about keeping this vaccine up to date. If your dog is not up to date on their bordatella and we have not received updated records within 4 hours of drop off we will administer this vaccine and a $15 charge will be added to your bill! "
              }
            />
            <CompactInfoSection
              props={{ style: { marginBottom: 0, marginTop: 20 } }}
              header={"Diarrhea"}
              text={
                "Being around friends is super exciting! And getting picked up by your owners is exciting too! It is not uncommon for dogs to go home with loose stool after daycare or overnight boarding. If this persists more than 2 days please let us know and go see your veterinarian! We always need to know if there is something going around. "
              }
            />
            <CompactInfoSection
              props={{ style: { marginBottom: 0, marginTop: 20 } }}
              header={"Play and Injury Risk"}
              text={
                "There is always a risk of injury when animals are interacting! Even with an attendant present at all times bites, nicks, and scrapes can happen in seconds. Dogs with shorter hair that like to rough house are especially susceptible to superficial scrapes and cuts. These are the marks of play that all dogs have but that can be especially visible with shorter hair. Our experienced staff will assess all injuries and will write up incident reports for any event that could have resulted in an injury (even if there is no visible injury at the time). You will be called if any injury needs veterinary attention and we do cover any vet bills if the dog was not at fault! "
              }
            />
          </div>
        </Carousel>
      </div>
      <FirstTime />
      <FacebookSection />
    </div>
  );
};
export default AboutScreen;
