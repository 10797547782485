import React, { useContext } from "react";
import LocationCard from "./LocationCard";
import MapContainer from "./../MapContainer";
import HomeMapping from "./../../mappings/home";
import Subheader from "./../Subheader";

const Location = ({ hideSubtitle }) => {
  let subtitleBlock = <Subheader text={"OUR LOCATIONS"} />;
  if (!!hideSubtitle) {
    subtitleBlock = null;
  }
  return (
    <div className="location-container">
      <div className="location-sub-container">
        {subtitleBlock}
        <div className="location-cards">
          {HomeMapping.locations.map((location, index) => {
            return (
              <LocationCard
                key={index}
                locationGeneralInformation={location.GeneralInformation}
                primary={index % 2 === 0}
                contactType={location.id === "Belmont" ? "belmont" : "maple"}
                locationHours={location.Hours}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Location;
