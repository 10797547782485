import React, { useContext } from "react";
import ServiceCard from "./serviceCard";
import NavButton from "./../NavButton";
import CONSTS from "./../../consts";
import HomeMappnig from "./../../mappings/home";
import InfoSection from "./../InfoSection/index";

let { WEBMAPPINGTYPES } = CONSTS;

const Services = () => {
  let services = HomeMappnig.services;

  return (
    <div style={{ backgroundColor: "#F0F9F9", float: "left", width: "100%" }}>
      <InfoSection
        title={"Your one stop shop, for a home away from home."}
        subtitle={"OUR SERVICES"}
        props={{ style: { paddingBottom: 0 } }}
      />
      <div className="services-container">
        <div className="services-card-container-container">
          <ServiceCard
            title={services.Daycare.Title}
            text={services.Daycare.Description}
            imgSrc="daycare.webp"
            serviceType="daycare"
          />
          <ServiceCard
            title={services.Boarding.Title}
            text={services.Boarding.Description}
            imgSrc="boarding2.webp"
            serviceType="boarding"
          />
          <ServiceCard
            title={services.Grooming.Title}
            text={services.Grooming.Description}
            imgSrc="grooming2.webp"
            serviceType="grooming"
          />
        </div>
        <div className="button-container">
          <NavButton
            linkTo={WEBMAPPINGTYPES.ButtonLinkTo.Services}
            type={WEBMAPPINGTYPES.Button.Secondary}
          />
        </div>
      </div>
    </div>
  );
};
export default Services;
