import React, { useContext, useState, useEffect, useRef } from "react";
import { Helmet } from 'react-helmet'
import { Tabs, Carousel, Button } from "antd";

import InfoSection from "./../components/InfoSection";
import CompactInfoSection from "./../components/compactInfoSection";
import Menu from "./../components/Menu";

import ServiceCardDetails from "../components/cardDetails";
import ImgInfoSection from "./../components/ImgInfoSection";

import ServicesMapping from "./../mappings/services";
import { ServicesStore } from "./../store/ServicesStore";
import CONSTS from "./../consts";
import FirstTime from "./../components/FirstTime/index";

const { WEBMAPPINGTYPES } = CONSTS;

const { TabPane } = Tabs;

const ServicesScreen = () => {
  const carouselRef = useRef(null);
  const [carouselIndex, setCarouselIndex] = useState("daycare");
  const [carouselFinishedTransition, setCarouselFinishedTrasition] = useState(
    true
  );
  const [navClicked, setNavClicked] = useState(true);

  useEffect(() => {

  }, [carouselIndex]);

  const servicesContext = useContext(ServicesStore);
  let { service } = servicesContext;

  let [activeTab, setActiveTab] = useState(service);

  useEffect(() => {
    setActiveTab(service);
  }, [service]);

  let serviceContent = ServicesMapping;

  const onClick = (service) => {
    setNavClicked(true);
    if (carouselFinishedTransition) {
      switch (service) {
        case "daycare":
          setCarouselIndex("daycare");
          carouselRef.current.goTo(0);
          break;
        case "boarding":
          setCarouselIndex("boarding");
          carouselRef.current.goTo(1);
          break;
        case "grooming":
          setCarouselIndex("grooming");
          carouselRef.current.goTo(2);
          break;
        case "taxi":
          setCarouselIndex("taxi");
          carouselRef.current.goTo(3);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div id="screen-container" className="services-screen-container">
      <Helmet>
        <title>Services and Pricing | Abbie's Doghouse</title>
      </Helmet>
      <InfoSection
        title={"Your one stop dog shop!"}
        subtitle={"OUR SERVICES"}
        text={
          "Our pack is so pawsome, that space for new pack members are limited! Please email us to schedule your first pup's visit. If you have any questions regarding services or pricing, please don't hesistate to reach out!"
        }
        props={{ style: { paddingBottom: 0 } }}
      />
      <div style={{ float: "left", width: "100%", backgroundColor: "#F0F9F9" }}>
        <div className="container button-container">
          <Button
            onClick={() => {
              onClick("daycare");
            }}
            className={
              "secondary " + (carouselIndex === "daycare" ? "active" : "")
            }
          >
            DAYCARE
          </Button>
          <Button
            onClick={() => {
              onClick("boarding");
            }}
            className={
              "secondary " + (carouselIndex === "boarding" ? "active" : "")
            }
          >
            BOARDING
          </Button>
          <Button
            onClick={() => {
              onClick("grooming");
            }}
            className={
              "secondary " + (carouselIndex === "grooming" ? "active" : "")
            }
          >
            GROOMING
          </Button>
          <Button
            onClick={() => {
              onClick("taxi");
            }}
            className={
              "secondary " + (carouselIndex === "taxi" ? "active" : "")
            }
          >
            TAXI
          </Button>
        </div>
        <Carousel
          style={{ paddingBottom: 40 }}
          beforeChange={() => {
            setCarouselFinishedTrasition(false);
            setNavClicked(false);
          }}
          afterChange={(current) => {
            setCarouselFinishedTrasition(true);
            if (!navClicked) {
              setCarouselIndex(current);
            }
          }}
          autoplay={false}
          dots={false}
          ref={(ref) => (carouselRef.current = ref)}
        >
          <div key={"daycare"}>
            <ServiceCardDetails
              title={serviceContent.daycare.header}
              text={serviceContent.daycare.text}
              type="daycare"
              buttonType={"secondary"}
            />
            <InfoSection
              className="pricing-info-header"
              title={"Our Package Options"}
              text={serviceContent.daycare.package.description}
            />
            <div className="menu-container">
              <Menu menuItemsArr={serviceContent.daycare.package.prices} />
            </div>
            <InfoSection
              className="pricing-info-header"
              title={"Our Weekly Options"}
              text={
                "We also have weekly rates for clients that do not want to buy a larger package. To get on the weekly rate plan we keep a credit card on file and charge every Friday based on the number of days of daycare your dog attended that week.  We call this the Charge on Friday Plan."
              }
            />
            <div className="menu-container">
              <Menu menuItemsArr={serviceContent.daycare.weekly.prices} />
            </div>
          </div>
          <div key={"boarding"}>
            <ServiceCardDetails
              title={serviceContent.boarding.header}
              text={serviceContent.boarding.text}
              type="boarding"
              buttonType={"secondary"}
            />
            <InfoSection
              className="pricing-info-header"
              title={"Boarding Prices"}
              text={
                "Overnight boarders stay in large rooms with beds and blankets provided. They can stay alone or with a roommate but we try to make sure everyone who would enjoy a roommate has a roommate to decrease anxiety!"
              }
            />
            <div className="menu-container">
              <Menu menuItemsArr={serviceContent.boarding.prices} />
            </div>
            <div className="container">
              <CompactInfoSection
                header={"Drop off and Pick up"}
                text={serviceContent.boarding.tips.DropOffAndPickUp}
              />
              <CompactInfoSection
                header={"General Boarding Information"}
                text={serviceContent.boarding.tips.GeneralInformation}
              />
              <CompactInfoSection
                header={"Pre-portioned Food Guide"}
                text={serviceContent.boarding.tips.FoodGuide}
              />
            </div>
          </div>
          <div key={"grooming"}>
            <ServiceCardDetails
              title={"Treat your dog to a spaw day!"}
              text={
                "Smelly dog? Does dog hair cover your house? Long nails diriving you nuts? We've got you covered with our additional spaw grooming services, from baths, brush-outs and nail trims among many other services. Browse the menu and let us be your one stop shop for all your dogs' needs!"
              }
              type="grooming"
              buttonType={"secondary"}
            />
            <InfoSection
              className="pricing-info-header"
              title={"Bath Pricing"}
              text={
                "We can bathe your dog while they are here for daycare or boarding! Our baths include shampoo and blow dry with a quick brush out! Ask us if your dog needs oatmeal shampoo at no additional charge! Pricing ranges based on hair length and difficulty."
              }
              props={{ style: { paddingBottom: 0, marginTop: 15 } }}
            />
            <div className="menu-container">
              <h1>Short Hair Bath Prices</h1>
              <Menu
                menuItemsArr={serviceContent.grooming.bath.shortHair.prices}
              />
            </div>
            <div className="menu-container">
              <h1>Long Hair Bath Prices</h1>
              <Menu
                menuItemsArr={serviceContent.grooming.bath.longHair.prices}
              />
            </div>
            <InfoSection
              className="pricing-info-header"
              title={"Other Spa Services"}
              text={
                "We can brush out your dog with our Furminator and trim your nails to give your pup leaving nice and pampered! We never use any restraining tools at the Dog house - if your dog hates nail trims, please have your vet trim their nails! We Want your dog to LOVE coming to the Dog House."
              }
              props={{ style: { marginTop: 15 } }}
            />
            <div className="menu-container">
              <Menu menuItemsArr={serviceContent.grooming.prices} />
            </div>
          </div>
          <div key={"taxi"}>
            <ServiceCardDetails
              title={"We can safely taxi your dog to and from our Doghouses!"}
              text={
                "Taxis are available for pick up and drop off Monday-Friday. Taxi pricing is based on distance and ease of travel. Prices range from $5-$25 a taxi and we travel up to 3.5 miles for taxis. Please email us for more information on taxi pricing and booking!"
              }
              type="taxi"
              buttonType={"secondary"}
            />
            <InfoSection
              className="pricing-info-header"
              title={"Taxi Pricing"}
              text={
                "Taxi pricing is primarily based on distance and is charged based on a ride basis. Anywhere outside of 3.5 miles of the dog house is unfortunately out of our taxi coverage zone."
              }
            />
            <div className="menu-container">
              <Menu menuItemsArr={serviceContent.taxi.prices} />
            </div>
            <div className="menu-container">
              <h1>Additional Taxi Charges</h1>
              <p>Charged per taxi ride</p>
              <Menu menuItemsArr={serviceContent.taxi.additional.prices} />
            </div>
          </div>
        </Carousel>
      </div>
      <FirstTime />
    </div>
  );
};

export default ServicesScreen;
