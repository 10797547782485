import React from "react";
import NavButton from "./NavButton";
import InfoSection from "./InfoSection/index";
import Subheader from "./Subheader";
import CONSTS from "./../consts/index";

let { WEBMAPPINGTYPES } = CONSTS;

const ImgInfoSection = ({
  title,
  subtitle,
  text,
  buttonItem,
  imgSrc,
  props,
  btnLinkTo,
}) => {
  var titleBlock = null;
  if (title.indexOf("<br/>") > 0) {
    titleBlock = (
      <span>
        <h1>{title.split("<br/>")[0]}</h1>
        <h1>{title.split("<br/>")[1]}</h1>
      </span>
    );
  } else {
    titleBlock = <h1>{title}</h1>;
  }

  let buttonBlock;
  if (!!buttonItem) {
    buttonBlock = buttonItem;
  } else {
    buttonBlock = (
      <NavButton
        linkTo={btnLinkTo}
        type={WEBMAPPINGTYPES.Button.Primary}
        text={"LEARN MORE"}
      />
    );
  }

  return (
    <div className="img-info-section-container" {...props}>
      <div className={"img-info-section-sub-container"}>
        <div className="img-info-section-content">
          <Subheader text={subtitle} secondary={true} />
          {titleBlock}
          <p>{text}</p>
          {buttonBlock}
        </div>
        <div className="img-info-section-img">
          <img src={`img/${imgSrc}`} alt="Abbie's Doghouse - Daycare and boarding in Dallas, TX" />
        </div>
      </div>
    </div>
  );
};

export default ImgInfoSection;
