import React from "react";
import InfoSection from "./InfoSection";
import CONSTS from "./../consts";

let { WEBMAPPINGTYPES } = CONSTS;

const FacebookSection = () => {
  return (
    <div className="facebook-section-container">
      <div className="facebook-section-sub-container">
        <InfoSection
          props={{
            style: { paddingBottom: 40, backgroundColor: "rgba(0,0,0,0)" },
          }}
          title={"Everyday is picture day"}
          subtitle={"FACEBOOK"}
          text={
            "We post over 100 photos of our dogs playing everyday - if your dog is staying with us, browse our photo collections to see what your dog is up to!"
          }
          buttonArr={[
            {
              LinkTo: WEBMAPPINGTYPES.ButtonLinkTo.BelmontGallery,
              Type: WEBMAPPINGTYPES.Button.Secondary,
            },
            {
              LinkTo: WEBMAPPINGTYPES.ButtonLinkTo.MapleGallery,
              Type: WEBMAPPINGTYPES.Button.Secondary,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default FacebookSection;
