import React, { useContext, useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Tabs, Carousel, Button } from "antd";
import InfoSection from "./../components/InfoSection/index";
import FirstTime from "./../components/FirstTime/index";
import { ContactStore } from "./../store/ContactStore";
import ContactForm from "./../components/ContactForm";
import HomeMapping from "./../mappings/home";

const { TabPane } = Tabs;

const ContactScreen = () => {
  const carouselRef = useRef(null);
  const [carouselIndex, setCarouselIndex] = useState(1);
  const [carouselFinishedTransition, setCarouselFinishedTrasition] = useState(
    true
  );
  const [navClicked, setNavClicked] = useState(true);

  const contactContext = useContext(ContactStore);
  let { contact } = contactContext;

  let [activeTab, setActiveTab] = useState(contact);

  let locationMapping = HomeMapping.locations;

  useEffect(() => {
    setActiveTab(contact);
  }, [contact]);

  const onClick = (index) => {
    setNavClicked(true);
    if (carouselFinishedTransition) {
      switch (index) {
        case 1:
          setCarouselIndex(1);
          carouselRef.current.goTo(0);
          break;
        case 2:
          setCarouselIndex(2);
          carouselRef.current.goTo(1);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div id="screen-container" className="contact-screen-container">
      <Helmet>
        <title>Contact | Abbie's Doghouse</title>
      </Helmet>
      <InfoSection
        title={"Drop us a line!"}
        subtitle={"CONTACT US"}
        text={
          "Appointments are accepted by phone and by email! We prefer first time clients to email so we can send our paperwork! Please select the desired location you would like to reach out to and shoot us a message! If you're planning a first visit with us be sure to check out our guidelines and informations below."
        }
        props={{
          style: { backgroundColor: "white", marginTop: 0, paddingBottom: 0 },
        }}
      />
      <div style={{ width: "100%", float: "left", backgroundColor: "#F0F9F9" }}>
        <div className="container">
          <div className="button-container">
            <Button
              onClick={() => {
                onClick(1);
              }}
              className={"secondary " + (carouselIndex === 1 ? "active" : "")}
            >
              CONTACT DOGHOUSE ON BELMONT
            </Button>
            <Button
              onClick={() => {
                onClick(2);
              }}
              className={"secondary " + (carouselIndex === 2 ? "active" : "")}
            >
              CONTACT DOGHOUSE ON MAPLE
            </Button>
          </div>
        </div>
        <Carousel
          style={{ paddingBottom: 70 }}
          beforeChange={() => {
            setCarouselFinishedTrasition(false);
            setNavClicked(false);
          }}
          afterChange={(current) => {
            setCarouselFinishedTrasition(true);
            if (!navClicked) {
              setCarouselIndex(current);
            }
          }}
          autoplay={false}
          dots={false}
          ref={(ref) => (carouselRef.current = ref)}
        >
          <ContactForm
            key={1}
            locationEmail={locationMapping[0].GeneralInformation.Email}
            locationGeneralInformation={locationMapping[0].GeneralInformation}
          />
          <ContactForm
            key={2}
            locationEmail={locationMapping[1].GeneralInformation.Email}
            locationGeneralInformation={locationMapping[1].GeneralInformation}
          />
        </Carousel>
      </div>
      <FirstTime />
    </div>
  );
};
export default ContactScreen;
