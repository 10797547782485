import CONSTS from "./../consts/index";
class ButtonModel {
  constructor(linkTo, type) {
    this.LinkTo = linkTo;
    this.Type = type;
  }
}

let { WEBMAPPINGTYPES } = CONSTS;

let homeInfoSectionButton1 = new ButtonModel(
  WEBMAPPINGTYPES.ButtonLinkTo.Reserve,
  WEBMAPPINGTYPES.Button.Primary
);
let homeInfoSectionButton2 = new ButtonModel(
  WEBMAPPINGTYPES.ButtonLinkTo.About,
  WEBMAPPINGTYPES.Button.Secondary
);

export default {
  Title:
    "We are an owner run business that will always put our dogs first.",
  Text:
    "Abbie’s Dog House on Belmont is a completely cage-free dog daycare and boarding facility in Dallas, TX. Our goal is to make each of our clients feel like a part of our family. Everyone at Abbie's will treat your dog like they are our own!",
  ButtonArr: [homeInfoSectionButton1, homeInfoSectionButton2],
};