module.exports = {
  Button: {
    Primary: "__WEBSITE_VALUE_TYPE_BUTTON_PRIMARY__",
    Secondary: "__WEBSITE_VALUE_TYPE_BUTTON_SECONDARY__"
  },
  ButtonLinkTo: {
    Services: "services",
    BelmontGallery: "belmont_gallery",
    MapleGallery: "maple_gallery",
    About: "about",
    Reserve: "reserve",
    Location: "location",
    Contact: "contact"
  },
  Alignment: {
    Left: "__WEBSITE_VALUE_TYPE_ALIGNMENT_LEFT__",
    Center: "__WEBSITE_VALUE_TYPE_ALIGNMENT_CENTER__",
    Right: "__WEBSITE_VALUE_TYPE_ALIGNMENT_RIGHT__"
  }
}