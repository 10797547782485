import React, { useContext } from "react";
import { Button } from "antd";
import CONSTS from "../consts/index";
import { Link } from "react-router-dom";
import { ServicesStore } from "./../store/ServicesStore";
import { ContactStore } from "./../store/ContactStore";

const { WEBMAPPINGTYPES } = CONSTS;

const NavButton = ({ linkTo, type, text, props }) => {
  const servicesContext = useContext(ServicesStore);
  let { setService } = servicesContext;
  const contactContext = useContext(ContactStore);
  let { setContact } = contactContext;

  if (!!linkTo) {
    let buttonText = "";
    let linkToRoute = "";

    switch (linkTo) {
      case WEBMAPPINGTYPES.ButtonLinkTo.About:
        buttonText = <span>ABOUT US</span>;
        linkToRoute = "/about";
        break;
      case WEBMAPPINGTYPES.ButtonLinkTo.Location:
        buttonText = <span>OUR LOCATIONS</span>;
        linkToRoute = "/locations";
        break;
      case WEBMAPPINGTYPES.ButtonLinkTo.Reserve:
        buttonText = <span>BOOK RESERVATION</span>;
        linkToRoute = "/contact";
        break;
      case WEBMAPPINGTYPES.ButtonLinkTo.Services:
        buttonText = !!text ? <span>{text}</span> : <span>OUR SERVICES</span>;
        linkToRoute = "/services";
        break;
      case WEBMAPPINGTYPES.ButtonLinkTo.BelmontGallery:
        buttonText = <span>BELMONT GALLERY</span>;
        break;
      case WEBMAPPINGTYPES.ButtonLinkTo.MapleGallery:
        buttonText = <span>MAPLE GALLERY</span>;
        break;
      case WEBMAPPINGTYPES.ButtonLinkTo.Contact:
        buttonText = <span>CONTACT US</span>;
        linkToRoute = "/contact";
        break;
      default:
        if (!!text) {
          buttonText = <span>{text}</span>;
        }
        break;
    }

    if (!!text) {
      if (text.indexOf("<br/>") > 0) {
        buttonText = (
          <span>
            <span>{text.split("<br/>")[0]}</span>
            <span style={{ display: "block", marginBottom: "-0.5em" }}></span>
            <span>{text.split("<br/>")[1]}</span>
          </span>
        );
      } else {
        buttonText = <span>{text}</span>;
      }
    }

    let buttonClass = "";
    switch (type) {
      case WEBMAPPINGTYPES.Button.Primary:
        buttonClass = "primary";
        break;
      case WEBMAPPINGTYPES.Button.Secondary:
        buttonClass = "secondary";
        break;
      default:
        buttonClass = "primary";
        break;
    }

    return (
      <Link
        to={linkToRoute}
        onClick={() => {
          if (
            linkTo === WEBMAPPINGTYPES.ButtonLinkTo.Services &&
            text !== "TAXI SERVICE"
          ) {
            setService("daycare");
          }
          if (linkTo === WEBMAPPINGTYPES.ButtonLinkTo.Contact) {
            setContact("belmont");
          }
          if (linkTo === WEBMAPPINGTYPES.ButtonLinkTo.BelmontGallery) {
            window.open(
              "https://www.facebook.com/doghousebelmont/photos/?ref=page_internal"
            );
          }
          if (linkTo === WEBMAPPINGTYPES.ButtonLinkTo.MapleGallery) {
            window.open(
              "https://www.facebook.com/DogHouseMaple/photos/?ref=page_internal"
            );
          }
        }}
      >
        <Button className={buttonClass} {...props}>
          {buttonText}
        </Button>
      </Link>
    );
  } else {
    return null;
  }
};

export default NavButton;
