const SendEmail = async (locationEmail, name, email, subject, message) => {
  if (!!name && !!email && !!subject && !!message) {
    return await fetch("/api/email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        locationEmail,
        name,
        email,
        subject,
        message,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.success) {
          return true;
        } else {
          return false;
        }
      });
  } else {
    return false;
  }
};

export { SendEmail };
