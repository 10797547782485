import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Layout, Menu, Drawer } from "antd";
import NavButton from "./NavButton";
import CONSTS from "./../consts/index";

const { Header } = Layout;
const { WEBMAPPINGTYPES } = CONSTS;

const _Header = () => {
  const [drawerToggled, toggleDrawer] = useState(false);
  const [selectedMenu, toggleMenu] = useState(null);
  let path = useLocation().pathname;

  useEffect(() => {
    toggleMenu(path);
    window.scrollTo(0, 0);
  }, [path]);

  const drawerToggleOnClick = () => {
    toggleDrawer(true);
  };
  const onDrawerClose = () => {
    toggleDrawer(false);
  };
  const handleMenuClick = (e) => {
    toggleMenu(e.key);
    window.scrollTo(0, 0);
  };

  return (
    <div id={"header-container"}>
      <Header className="header">
        <div className="header-logo-container">
          <Link
            to="/"
            className="header-logo-image"
            onClick={() => {
              toggleMenu(null);
            }}
          >
            <img
              src="./img/logo.svg"
              alt="Abbie's Doghouse - Daycare and boarding in Dallas, TX"
              className="header-logo-image"
            />
          </Link>
        </div>
        <div className="menu-container">
          <a className="drawer-link" onClick={drawerToggleOnClick}>
            <i className="fas fa-bars"></i>
          </a>
          {/* <NavButton
            linkTo={WEBMAPPINGTYPES.ButtonLinkTo.Reserve}
            type={WEBMAPPINGTYPES.Button.Primary}
            props={{
              className: "header-button",
            }}
          /> */}
          <_NavigationItems
            className="header-menu"
            mode="horizontal"
            selectedKeys={selectedMenu}
            handleMenuClick={handleMenuClick}
          />
        </div>
        <Drawer
          placement="top"
          visible={drawerToggled}
          onClose={onDrawerClose}
          className="drawer"
          width="100%"
          height="50%"
        >
          <_NavigationItems
            className="drawer-menu"
            mode="vertical"
            selectedKeys={selectedMenu}
            handleMenuClick={handleMenuClick}
            toggleDrawer={toggleDrawer}
          />
        </Drawer>
      </Header>
    </div>
  );
};

const _NavigationItems = ({
  className,
  mode,
  selectedKeys,
  handleMenuClick,
  toggleDrawer,
}) => {
  const onMenuPress = () => {
    if (!!toggleDrawer) {
      toggleDrawer(false);
    }
  };

  return (
    <div>
      <NavButton
        linkTo={WEBMAPPINGTYPES.ButtonLinkTo.Reserve}
        type={WEBMAPPINGTYPES.Button.Primary}
        props={{
          className: "header-button",
        }}
        onClick={onMenuPress}
      />
      <Menu
        className={className}
        mode={mode}
        selectedKeys={[selectedKeys]}
        onClick={handleMenuClick}
      >
        <Menu.Item key="/locations">
          <Link to="/locations" onClick={onMenuPress}>
            LOCATIONS
          </Link>
        </Menu.Item>
        <Menu.Item key="/services">
          <Link to="/services" onClick={onMenuPress}>
            SERVICES AND PRICING
          </Link>
        </Menu.Item>
        <Menu.Item key="/about">
          <Link to="/about" onClick={onMenuPress}>
            ABOUT
          </Link>
        </Menu.Item>
        <Menu.Item key="/contact">
          <Link to="/contact" onClick={onMenuPress}>
            CONTACT
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default _Header;
