import React from "react";
import CONSTS from "./../../consts";

const { WEBMAPPINGTYPES } = CONSTS;

const _Slide = ({ carouselItem }) => {
  return (
    <div className={"carousel-slide " + carouselItem.ImageSrc.split(".")[0]}>
      <div className="carousel-container"></div>
    </div>
  );
};

export default _Slide;
