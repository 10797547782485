import React, { useContext, useRef, useEffect, useState } from "react";
import { Carousel, Rate } from "antd";
import NavButton from "./NavButton";
import InfoSection from "./InfoSection/index";
import Subheader from "./Subheader";
import CONSTS from "../consts/index";

let { WEBMAPPINGTYPES } = CONSTS;

const ReviewSection = ({ title, subtitle, text, buttonArr, imgSrc, props }) => {
  const carouselRef = useRef(null);
  const intervalRef = useRef(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [carouselFinishedTransition, setCarouselFinishedTrasition] = useState(
    true
  );
  const [arrowClicked, setArrowClicked] = useState(true);

  var titleBlock = null;
  if (title.indexOf("<br/>") > 0) {
    titleBlock = (
      <span>
        <h1>{title.split("<br/>")[0]}</h1>
        <h1>{title.split("<br/>")[1]}</h1>
      </span>
    );
  } else {
    titleBlock = <h1>{title}</h1>;
  }
  const arrowOnClick = (isLeft) => {
    setArrowClicked(true);
    if (carouselFinishedTransition) {
      if (isLeft) {
        let index = carouselIndex === 0 ? 3 - 1 : carouselIndex - 1;
        setCarouselIndex(index);
        carouselRef.current.prev();
      } else {
        let index = carouselIndex === 3 - 1 ? 0 : carouselIndex + 1;
        setCarouselIndex(index);
        carouselRef.current.next();
      }
      clearInterval(intervalRef.current);
    }
  };

  return (
    <div className="review-section-container" {...props}>
      <div className={"review-section-sub-container"}>
        <div className="review-section-content">
          <Subheader text={subtitle} secondary={false} />
          {titleBlock}
          <span className={"button-container"}>
            <NavButton
              linkTo={"external"}
              type={WEBMAPPINGTYPES.Button.Secondary}
              text={"MORE BELMONT <br/> REVIEWS"}
              props={{
                onClick: () => {
                  window.open(
                    "https://www.google.com/search?q=abbies+doghouse+on+belmont&oq=abbies+doghouse+on+belmont&aqs=chrome.0.69i59j46j0.3656j0j7&sourceid=chrome&ie=UTF-8#lrd=0x864e9f2bb1bd288d:0x940a50a80ef23cb6,1,,,"
                  );
                },
              }}
            />
            <NavButton
              linkTo={"external"}
              type={WEBMAPPINGTYPES.Button.Secondary}
              text={"MORE MAPLE <br/> REVIEWS"}
              props={{
                onClick: () => {
                  window.open(
                    "https://www.google.com/search?q=abbies+doghouse+on+maple&oq=abbies+doghouse+on+maple&aqs=chrome.0.69i59j46.2414j0j9&sourceid=chrome&ie=UTF-8#lrd=0x864e9d4f48d1785d:0xde379e59eac78134,1,,,"
                  );
                },
              }}
            />
          </span>
        </div>
        <div className={"review-section-carousel"}>
          <Carousel
            beforeChange={() => {
              setCarouselFinishedTrasition(false);
              setArrowClicked(false);
            }}
            afterChange={(current) => {
              setCarouselFinishedTrasition(true);
              if (!arrowClicked) {
                setCarouselIndex(current);
              }
            }}
            ref={(ref) => (carouselRef.current = ref)}
            className="review-carousel"
            autoplay={false}
            dots={false}
          >
            <ReviewSectionSlide
              key={1}
              name={"Shawna Watson"}
              text={
                "They treat my pup as if he is their own! Taxi service is clutch for those of us with nontraditional work hours. Abbie regularly emails customers to ensure we are up to date on policies/procedures/upcoming events."
              }
            />
            <ReviewSectionSlide
              key={2}
              name={"Evan Schnittman"}
              text={
                "Nothing is more amazing than how excited Lucy gets pulling up to Abbie's... And I love how they knew her name immediately and seem to love her too!"
              }
            />
            <ReviewSectionSlide
              key={3}
              name={"Shelby Haire"}
              text={
                "We love Abbie’s! All of the workers treat your dog like their own. They post pictures on their Facebook page throughout the day of all the pups playing, so you get to see what your pup is up to! Highly recommend for daycare for your pup in east Dallas!"
              }
            />
          </Carousel>
          <a
            className={"arrow left"}
            onClick={() => {
              arrowOnClick(true);
            }}
          >
            <img src={"./img/arrow-left.svg"} />
          </a>
          <a
            className={"arrow right"}
            onClick={() => {
              arrowOnClick(false);
            }}
          >
            <img src={"./img/arrow-right.svg"} />
          </a>
        </div>
      </div>
    </div>
  );
};

const ReviewSectionSlide = ({ name, text }) => {
  return (
    <div className={"slide-container"}>
      <p>{text}</p>
      <span className={"reviewer-block"}>
        <h1>{name}</h1>
        <Rate defaultValue={5} disabled={true} />
      </span>
    </div>
  );
};

export default ReviewSection;
