import React from "react";
const CompactInfoSection = ({ header, text, props }) => {
  return (
    <div className="compact-info-section" {...props}>
      <h1>{header}</h1>
      <p>{text}</p>
    </div>
  );
};
export default CompactInfoSection;
