import React from "react";
import { Link } from "react-router-dom";

const styles = {
  maxWidth: 900,
  margin: "0 auto",
  textAlign: "center",
  paddingTop: 150,
  paddingBottom: 150,
  height: 600,
};

const My404Component = () => {
  return (
    <div style={{ ...styles }}>
      <h1>Page not found!</h1>
      <p>This is not the page you are looking for.</p>
      <Link to="/">Click here to go back home</Link>
    </div>
  );
};

export default My404Component;
