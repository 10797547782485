import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/css/antd.css";
import "./styles/index.css";

import { Layout } from "antd";

import Header from "./components/Header";

import HomeScreen from "./screens/home";
import LocationScreen from "./screens/location";
import ServicesScreen from "./screens/services";
import AboutScreen from "./screens/about";
import ContactScreen from "./screens/contact";
import Footer from "./components/Footer";
import My404Component from "./components/My404Component";

import { ServicesProvider } from "./store/ServicesStore";
import { ContactProvider } from "./store/ContactStore";

const { Content } = Layout;

const App = () => {
  return (
    <Router>
      <ServicesProvider>
        <ContactProvider>
          <Layout>
            <Header />
            <Content>
              <Switch>
                <Route exact path="/">
                  <HomeScreen />
                </Route>
                <Route exact path="/locations">
                  <LocationScreen />
                </Route>
                <Route exact path="/services">
                  <ServicesScreen />
                </Route>
                <Route exact path="/about">
                  <AboutScreen />
                </Route>
                <Route exact path="/contact">
                  <ContactScreen />
                </Route>
                <Route path='*' exact={true} component={My404Component} />
              </Switch>
            </Content>
            <Footer />
          </Layout>
        </ContactProvider>
      </ServicesProvider>
    </Router>
  );
};

export default App;
