import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import Carousel from "./../components/Carousel";
import Location from "./../components/Location";
import InfoSection from "./../components/InfoSection";
import Services from "./../components/Services";
import TaxiSection from "./../components/Taxi";
import Reviews from "./../components/Reviews";
import FacebookSection from "./../components/facebookSection";
import FirstTime from "./../components/FirstTime";
import ImgInfoSection from "./../components/ImgInfoSection";
import ReviewSection from "./../components/ReviewSection";

import InformationSection from "./../mappings/informationSection";

import CONSTS from "./../consts/index";
let { WEBMAPPINGTYPES } = CONSTS;

const HomeScreen = () => {
  let infoSection = InformationSection;

  return (
    <div id="screen-container">
    <Helmet>
      <title>Abbie's Doghouse | Daycare and Boarding for Dogs in Dallas, TX</title>
    </Helmet>
      <Carousel />
      <InfoSection
        title={"Locally owned, and always putting our dogs first."}
        subtitle={"WHO WE ARE"}
        text={
          "Abbie’s DogHouse on Belmont and Maple is a completely cage-free dog daycare and boarding facilities in Dallas, TX. Our goal is to make each of our clients feel like a part of our family, and our top priority is the safety and well-being of our dogs. Everyone at Abbie's will treat your dog like they are our own!"
        }
      />
      <Location />
      <Services />
      <ImgInfoSection
        title={"Need a lift? <br/> We're here to help"}
        subtitle={"TAXI SERVICE"}
        text={
          "For owners out there who need an extra paw, we provide a taxi service for eligible residencies where we pick up and drop off your dog!"
        }
        imgSrc={"taxi3.webp"}
        btnLinkTo={WEBMAPPINGTYPES.ButtonLinkTo.Services}
      />
      <ReviewSection
        title={"Some thoughts from our human friends"}
        subtitle={"OUR CUSTOMERS"}
        btnLinkTo={WEBMAPPINGTYPES.ButtonLinkTo.Services}
      />
      <FacebookSection />
      <FirstTime />
    </div>
  );
};

export default HomeScreen;
