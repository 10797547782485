import React from "react";
import NavButton from "./../NavButton";
import Subheader from "./../Subheader";

const InfoSection = ({
  title,
  subtitle,
  text,
  buttonArr,
  props,
  className,
}) => {
  var titleBlock = null;
  if (!!title && title.indexOf(",") > 0) {
    titleBlock = (
      <span>
        <h1>{title.split(",")[0]},</h1>
        <h1>{title.split(",")[1]}</h1>
      </span>
    );
  } else {
    titleBlock = <h1>{title}</h1>;
  }

  let subtitleBlock = null;
  if (!!subtitle) {
    subtitleBlock = <Subheader text={subtitle} />;
  }

  return (
    <div className={`info-section-container ${className}`} {...props}>
      <div className="info-section-content" {...props}>
        {subtitleBlock}
        {titleBlock}
        <p>{text}</p>
        <span className="button-container">
          {!!buttonArr
            ? buttonArr.map((btn, i) => {
                return (
                  <NavButton key={i} linkTo={btn.LinkTo} type={btn.Type} />
                );
              })
            : null}
        </span>
      </div>
    </div>
  );
};

export default InfoSection;
