import React, { useState } from "react";
import FirstTimeContent from "./firstTimeContent";
import ImgInfoSection from "./../ImgInfoSection";
import { Button, Modal } from "antd";

const FirstTime = () => {
  let [modalState, setModalState] = useState({ visible: false });

  let onBtnClick = () => {
    setModalState({ visible: true });
  };

  return (
    <div className="first-time-container">
      <ImgInfoSection
        title={"Planning your first visit?"}
        subtitle={"FIRST DAY"}
        text={
          "Please review our guidelines and information on what to bring and what to expect on your pups first day with us."
        }
        imgSrc={"firstTime2.webp"}
        buttonItem={
          <Button className="primary" onClick={onBtnClick}>
            GUIDELINES AND INFORMATION
          </Button>
        }
      />

      <Modal
        visible={modalState.visible}
        onCancel={() => setModalState({ visible: false })}
        footer={null}
        width={800}
      >
        <FirstTimeContent />
      </Modal>
    </div>
  );
};

export default FirstTime;
